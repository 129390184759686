
/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Types } from '@shapeable/core';

export const ApplicationLogoInverted: React.FC<Types.Classable> = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px" viewBox="0 0 280 60.26">
    <g transform="translate(-249.202 -3900.297)">
      <g transform="translate(328.921 3919.294)">
        <path d="M413.058,3936.412v17.25c0,4.3-2.921,7.112-7.594,7.112-4.433,0-6.872-2.542-7.559-6.357l4.193-.858c.412,2.027,1.477,3.23,3.332,3.23,1.787,0,3.093-1.1,3.093-3.437v-13.229h-7.559v-3.71Z" transform="translate(-397.904 -3936)" fill="#fff"/>
        <path d="M440.394,3936.412v14.741c0,3.746,1.306,5.636,4.742,5.636s4.742-1.89,4.742-5.636v-14.741h4.468v14.157c0,7.044-2.509,10.24-9.244,10.24s-9.175-3.2-9.175-10.24v-14.157Z" transform="translate(-415.447 -3936)" fill="#fff"/>
        <path d="M475.88,3956.47l3.058-2.955a7.813,7.813,0,0,0,6.151,2.955c2.784,0,4.227-1.34,4.227-3.368,0-1.615-.756-2.611-3.265-3.058l-2.268-.344c-4.845-.756-7.182-3.058-7.182-6.976,0-4.3,3.333-7.079,8.693-7.079a10.085,10.085,0,0,1,8.213,3.54l-3.093,2.92a6.386,6.386,0,0,0-5.36-2.474c-2.578,0-3.917,1.031-3.917,2.817,0,1.821,1.03,2.577,3.332,2.989l2.234.413c4.845.859,7.113,3.024,7.113,6.976,0,4.536-3.265,7.629-9,7.629A11.131,11.131,0,0,1,475.88,3956.47Z" transform="translate(-433.881 -3935.647)" fill="#fff"/>
        <path d="M521.632,3940.432V3960.4H517.1v-19.964h-6.8v-4.02h18.143v4.02Z" transform="translate(-449.758 -3936)" fill="#fff"/>
        <path d="M548.7,3960.4v-3.642h3.162v-16.7H548.7v-3.642h10.893v3.642h-3.2v16.7h3.2v3.642Z" transform="translate(-467.478 -3936)" fill="#fff"/>
        <path d="M573.978,3948.223c0-8.075,4.02-12.576,10.343-12.576,4.295,0,7.078,1.821,8.763,5.532l-3.883,2.061a4.746,4.746,0,0,0-4.879-3.573c-3.367,0-5.532,2.439-5.532,6.528v3.78c0,4.123,2.165,6.46,5.532,6.46a5.331,5.331,0,0,0,5.189-3.814l3.676,2.165a9.213,9.213,0,0,1-8.865,5.67C578,3960.457,573.978,3956.3,573.978,3948.223Z" transform="translate(-479.142 -3935.647)" fill="#fff"/>
        <path d="M616.029,3960.4v-23.984h15.807v4.02H620.565v5.636h9.965v4.02h-9.965v6.288h11.271v4.02Z" transform="translate(-498.544 -3936)" fill="#fff"/>
        <path d="M670.614,3949.126H657.453v11.27h-2.062v-23.984h2.062V3947.3h13.161v-10.893h2.061V3960.4h-2.061Z" transform="translate(-516.705 -3936)" fill="#fff"/>
        <path d="M700.15,3936.412v14.81c0,5.326,1.511,7.731,6.219,7.731,4.674,0,6.22-2.405,6.22-7.731v-14.81h2.062v14.363c0,7.044-2.2,10.034-8.35,10.034s-8.213-2.989-8.213-10.034v-14.363Z" transform="translate(-536.405 -3936)" fill="#fff"/>
        <path d="M739.445,3936.412h9.381c3.745,0,6.013,2.268,6.013,6.047,0,3.333-1.89,4.811-3.677,5.223v.137c2.234.275,4.6,2.062,4.6,5.808,0,3.952-2.405,6.769-6.083,6.769h-10.24Zm2.062,10.618h7.113c2.612,0,4.02-1.581,4.02-3.746v-1.306c0-2.2-1.409-3.745-4.02-3.745h-7.113Zm0,11.545h7.628c2.886,0,4.433-1.752,4.433-4.26v-1.272c0-2.474-1.546-4.227-4.433-4.227h-7.628Z" transform="translate(-555.486 -3936)" fill="#fff"/>
      </g>
      <g transform="translate(249.202 3900.297)">
        <path d="M303.553,3959.469q-3.584-2.387-7.166-4.781a4.009,4.009,0,0,0,.21-3.294,3.685,3.685,0,0,0-1.453-1.773,7.049,7.049,0,0,0,1.26-2.632,15.4,15.4,0,0,0,.439-5.01,8.463,8.463,0,0,0-.735-3.151,6.978,6.978,0,0,0-1.161-1.9,16.833,16.833,0,0,0,1.28-3.375,21.86,21.86,0,0,0,.6-6.618,16.183,16.183,0,0,0-1.335-5.947c8.53.4,16.036,3.582,20.869,8.34a25.962,25.962,0,0,0-45.349,0c4.8-4.725,12.237-7.9,20.694-8.328a14.342,14.342,0,0,0-1.213,4.5,12.594,12.594,0,0,0-.2,3.1c.087.808.1,1.623.2,2.428a14.2,14.2,0,0,0,1.189,4.472,10.554,10.554,0,0,0,.664,1.342,8.346,8.346,0,0,0-1.914,4.584,17.117,17.117,0,0,0,.013,3.783,8.949,8.949,0,0,0,1.6,4.409,3.682,3.682,0,0,0-1.672,2.643,4.114,4.114,0,0,0,.422,2.408q-3.507,2.407-7.018,4.812a4.584,4.584,0,0,0-1.984,2.386,2.767,2.767,0,0,0,.72,2.528,8.108,8.108,0,0,0,2.878,1.917,19.424,19.424,0,0,0,5.339,1.322,26.377,26.377,0,0,0,7.208-.181,16.926,16.926,0,0,0,4.807-1.5,6.3,6.3,0,0,0,2.487-2.061,2.536,2.536,0,0,0,.3-2.207A4.806,4.806,0,0,0,303.553,3959.469Zm-9.871-38.523h0Z" transform="translate(-259.262 -3907.547)" fill="#fff"/>
        <path d="M262.128,3992.118l.387.594-3.1,2.023-.387-.594-.677.441,1.319,2.024.677-.441-.383-.587,3.1-2.023.383.587.677-.441-1.319-2.024Z" transform="translate(-253.422 -3942.539)" fill="#fff"/>
        <path d="M259.852,3986.525l-3.468,1.634-.01-.021.766-.967,1.786-2.61-.471-1-4.812,2.268.406.862,3.468-1.634.01.021-.766.967-1.786,2.611.471,1,4.812-2.268Z" transform="translate(-251.251 -3938.781)" fill="#fff"/>
        <path d="M252.3,3980.355l4.252-1.235-.281-.966-4.252,1.236-.421-1.449-.857.249,1.123,3.864.856-.249Z" transform="translate(-249.912 -3936.18)" fill="#fff"/>
        <path d="M254.677,3968.387l-.883.125.35,2.474-1.381.2-.31-2.187-.882.125.309,2.188-1.237.175-.35-2.475-.882.125.491,3.471,5.266-.745Z" transform="translate(-249.299 -3931.766)" fill="#fff"/>
        <path d="M254.536,3962.652l-2.049-.033.015-.953,2.066-.957.019-1.12-2.182,1.055a1.466,1.466,0,0,0-1.492-1.015,1.5,1.5,0,0,0-1.671,1.543l-.039,2.4,5.318.085Zm-2.9-.046-1.547-.025.021-1.3a.553.553,0,0,1,.6-.615l.351,0a.561.561,0,0,1,.592.635Z" transform="translate(-249.202 -3927.699)" fill="#fff"/>
        <path d="M254.963,3953.187l-3.774-.672,0-.022,1.184-.346,2.967-1.1.194-1.088-5.237-.934-.167.938,3.774.673,0,.022-1.184.346-2.967,1.1-.194,1.088,5.237.933Z" transform="translate(-249.366 -3922.819)" fill="#fff"/>
        <path d="M257.367,3945.125l-1.134-.854.64-1.778,1.421.058.354-.982-5.61-.142-.423,1.176,4.406,3.482Zm-1.861-1.375-1.828-1.378.013-.036,2.29.1Z" transform="translate(-250.777 -3919.306)" fill="#fff"/>
        <path d="M256.217,3934.014l3.926,2.046.465-.892-3.926-2.046.7-1.338-.791-.412-1.86,3.568.79.412Z" transform="translate(-251.752 -3914.659)" fill="#fff"/>
        <path d="M260.132,3928.238l3.058,2.087-.395.579.667.456,1.362-1.995-.667-.455-.4.585-3.059-2.087.4-.585-.667-.455-1.361,1.995.667.455Z" transform="translate(-253.755 -3912.345)" fill="#fff"/>
        <path d="M269.113,3924.316c.94-1.042.845-2.358-.49-3.563s-2.655-1.165-3.594-.124-.846,2.358.49,3.563S268.179,3925.351,269.113,3924.316Zm-3.422-3.089a1.259,1.259,0,0,1,1.912.043l.611.551a1.263,1.263,0,1,1-1.675,1.856l-.611-.552A1.259,1.259,0,0,1,265.691,3921.227Z" transform="translate(-256.219 -3909.333)" fill="#fff"/>
        <path d="M274.692,3917.677l-2.363-3.019.018-.014,1.114.532,2.943,1.162.87-.681-3.28-4.189-.75.587,2.363,3.019-.018.014-1.113-.532-2.943-1.162-.87.682,3.28,4.189Z" transform="translate(-259.103 -3905.451)" fill="#fff"/>
        <path d="M283.563,3913.09l-.277-1.392,1.662-.9,1.021.989.918-.5-4.091-3.84-1.1.6.968,5.532Zm-.884-4.529,1.644,1.6-1.233.667-.445-2.246Z" transform="translate(-264.195 -3903.597)" fill="#fff"/>
        <path d="M293.414,3908.753l-.308-.836-1.967.723-1.528-4.155-.944.347,1.836,4.991Z" transform="translate(-267.411 -3902.229)" fill="#fff"/>
        <path d="M303.07,3906.166a1.429,1.429,0,0,0,1.118-1.719,1.165,1.165,0,0,0-1.329-1.047l-.008-.045a1,1,0,0,0,.763-1.277,1.294,1.294,0,0,0-1.605-1.128l-2.431.384.828,5.254Zm.053-1.721.04.256a.542.542,0,0,1-.512.667l-1.385.218-.222-1.408,1.385-.218A.547.547,0,0,1,303.123,3904.445Zm-1.192-2.621a.521.521,0,0,1,.659.467l.037.233a.525.525,0,0,1-.484.647l-1.227.193-.212-1.347Z" transform="translate(-272.444 -3900.586)" fill="#fff"/>
        <path d="M310.666,3903.582l.952.015.958,2.065,1.12.019-1.055-2.181a1.466,1.466,0,0,0,1.015-1.493,1.5,1.5,0,0,0-1.543-1.671l-2.4-.038-.086,5.318,1.006.016Zm.039-2.392,1.3.021a.555.555,0,0,1,.615.6l-.006.35a.559.559,0,0,1-.634.592l-1.3-.021Z" transform="translate(-277.081 -3900.297)" fill="#fff"/>
        <path d="M320.474,3905.4l-.7-.093.485-3.671.7.093.106-.8-2.4-.316-.106.8.7.092-.486,3.671-.695-.092-.106.8,2.395.316Z" transform="translate(-280.932 -3900.443)" fill="#fff"/>
        <path d="M325.5,3907.245c1.338.364,2.447-.259,2.911-1.964s-.178-2.8-1.517-3.168l-1.867-.507-1.394,5.132Zm.263-4.516.9.244a1.167,1.167,0,0,1,.83,1.623l-.22.809a1.168,1.168,0,0,1-1.537.98l-.9-.244Z" transform="translate(-283.542 -3900.901)" fill="#fff"/>
        <path d="M334.478,3910.877a1.265,1.265,0,0,0,1.65-.241l.027.013-.347.746.787.367,1.222-2.625-1.824-.849-.357.767.946.44-.144.311c-.254.545-.845.606-1.412.343a1.263,1.263,0,0,1-.591-1.855l.36-.774a1.256,1.256,0,0,1,1.786-.749,1.217,1.217,0,0,1,.747,1.229l.942-.091a2.1,2.1,0,0,0-1.313-1.947c-1.285-.6-2.563-.109-3.316,1.507S333.345,3910.35,334.478,3910.877Z" transform="translate(-287.991 -3902.798)" fill="#fff"/>
        <path d="M343.552,3915.9l.5-.738-2.07-1.4.78-1.155,1.831,1.237.5-.739-1.831-1.237.7-1.036,2.071,1.4.5-.739-2.9-1.961-2.977,4.406Z" transform="translate(-291.394 -3904.557)" fill="#fff"/>
        <path d="M349.875,3918.464l.32.4c.344.448.319.724.078.989-.3.332-.739.336-1.2-.08a1.731,1.731,0,0,1-.567-1.4l-.943.027a2.469,2.469,0,0,0,.869,1.988,1.726,1.726,0,0,0,2.616.094c.59-.647.542-1.341-.124-2.206l-.3-.4c-.316-.412-.372-.69-.1-.989s.641-.261,1.063.123a1.417,1.417,0,0,1,.509,1.207l.943-.017a2.237,2.237,0,0,0-.817-1.807,1.654,1.654,0,0,0-2.482-.138C349.155,3916.89,349.193,3917.616,349.875,3918.464Z" transform="translate(-294.586 -3907.427)" fill="#fff"/>
        <path d="M355.873,3927.609l.6.809,3.558-2.633.9,1.213.716-.53-2.393-3.234-.717.53.9,1.212Z" transform="translate(-298.419 -3910.898)" fill="#fff"/>
        <path d="M361.672,3932.43c-1.589.842-2,2.1-1.345,3.336s1.928,1.6,3.517.758,2-2.1,1.345-3.336S363.261,3931.587,361.672,3932.43Zm2.729,1.175a1.259,1.259,0,0,1-.694,1.783l-.728.386a1.264,1.264,0,1,1-1.171-2.209l.728-.386A1.258,1.258,0,0,1,364.4,3933.605Z" transform="translate(-300.336 -3914.937)" fill="#fff"/>
        <path d="M366.293,3946.877a.664.664,0,0,1-.922-.447.746.746,0,0,1,.482-.909l-.442-.84a1.574,1.574,0,0,0-.888,2,1.526,1.526,0,0,0,1.984,1.178l3.673-1.064-.747-2.577-.79.229.466,1.61Z" transform="translate(-302.37 -3920.597)" fill="#fff"/>
        <path d="M366.3,3954.6c.2,1.48.974,1.938,2.522,1.731l3.113-.416-.131-.982-3.24.433c-.824.11-1.278-.121-1.378-.877s.276-1.1,1.1-1.208l3.24-.433-.131-.982-3.113.416C366.732,3952.493,366.1,3953.123,366.3,3954.6Z" transform="translate(-303.213 -3924.132)" fill="#fff"/>
        <path d="M370.839,3964.926l.632.7a2.238,2.238,0,0,0,.825-1.8c.026-1.189-.575-1.941-1.526-1.962-.869-.019-1.391.488-1.582,1.558l-.087.5c-.111.554-.337.717-.694.709-.45-.009-.74-.336-.727-.954a1.735,1.735,0,0,1,.686-1.35l-.64-.692a2.468,2.468,0,0,0-.927,1.961c-.028,1.273.642,2.012,1.648,2.033.876.02,1.368-.473,1.581-1.543l.1-.493c.1-.509.275-.733.679-.725s.618.311.606.883A1.419,1.419,0,0,1,370.839,3964.926Z" transform="translate(-303.458 -3928.644)" fill="#fff"/>
        <path d="M370.675,3971.89l-4.371-.7-.16.993,4.371.7-.24,1.49.88.142.639-3.973-.88-.142Z" transform="translate(-303.157 -3932.696)" fill="#fff"/>
        <path d="M369.1,3978.751l-3.561-1.017.192-.674-.777-.222-.664,2.323.777.222.195-.682,3.561,1.017-.194.682.776.222.664-2.323-.777-.222Z" transform="translate(-302.304 -3935.67)" fill="#fff"/>
        <path d="M365.5,3983.6c-1.646-.708-2.845-.254-3.4,1.035a2.042,2.042,0,0,0,.378,2.3l.763-.559a1.182,1.182,0,0,1-.322-1.392,1.233,1.233,0,0,1,1.8-.56l.771.331a1.25,1.25,0,0,1,.845,1.7,1.053,1.053,0,0,1-1.156.681l.08.972a1.939,1.939,0,0,0,1.9-1.3C367.712,3985.518,367.147,3984.3,365.5,3983.6Z" transform="translate(-301.201 -3938.641)" fill="#fff"/>
        <path d="M357.078,3992.938l.757.471,1.319-2.123,1.184.737-1.167,1.876.758.47,1.166-1.876,1.061.66-1.319,2.123.757.47,1.85-2.976-4.516-2.808Z" transform="translate(-298.975 -3941.747)" fill="#fff"/>
        <path d="M271.892,3949.913c.8-1.362,1.566-2.739,2.315-4.125s1.471-2.784,2.181-4.188l1.049-2.115.015-.031.015.031c.351.7.693,1.413,1.049,2.113q1.064,2.108,2.182,4.188c.75,1.386,1.516,2.763,2.315,4.125s1.615,2.716,2.51,4.033c-.45-1.527-.991-3.011-1.526-4.5s-1.125-2.948-1.721-4.406-1.218-2.9-1.854-4.343c-.316-.721-.646-1.435-.968-2.152l-1-2.139a1.111,1.111,0,0,0-2.014,0l-1,2.138-.969,2.152c-.635,1.439-1.259,2.884-1.854,4.343s-1.175,2.924-1.72,4.407-1.075,2.971-1.523,4.5C270.277,3952.628,271.081,3951.269,271.892,3949.913Z" transform="translate(-258.512 -3916.697)" fill="#fff"/>
        <path d="M339.757,3945.04q-.9-2.187-1.854-4.343c-.316-.721-.647-1.435-.969-2.152l-1-2.139a1.111,1.111,0,0,0-2.014,0l-1,2.138-.969,2.152c-.636,1.439-1.26,2.884-1.854,4.343s-1.175,2.924-1.72,4.407-1.075,2.971-1.524,4.5c.9-1.316,1.7-2.676,2.513-4.032s1.566-2.739,2.315-4.125,1.471-2.784,2.181-4.188l1.049-2.115.015-.031.015.031c.35.7.692,1.413,1.049,2.113q1.064,2.108,2.182,4.188c.75,1.386,1.516,2.763,2.315,4.125s1.615,2.716,2.51,4.033c-.45-1.527-.991-3.011-1.526-4.5S340.352,3946.5,339.757,3945.04Z" transform="translate(-285.032 -3916.697)" fill="#fff"/>
        <path d="M285.7,3975.095a.9.9,0,0,0-.849-1.188H267.382a.9.9,0,0,0-.848,1.188,10.087,10.087,0,0,0,19.167,0Z" transform="translate(-257.177 -3934.321)" fill="#fff"/>
        <path d="M342.331,3973.907H324.862a.9.9,0,0,0-.849,1.188,10.088,10.088,0,0,0,19.167,0A.9.9,0,0,0,342.331,3973.907Z" transform="translate(-283.697 -3934.321)" fill="#fff"/>
      </g>
    </g>
  </svg>
);


