/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Types } from '@shapeable/core';

export const ApplicationLogo: React.FC<Types.Classable> = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px"  viewBox="0 0 385.198 82.9">
    <g transform="translate(-130.001 -10)">
      <g transform="translate(-119.201 -3890.297)">
        <g transform="translate(360.751 3926.556)">
          <path d="M418.609,3936.411v23.569c0,5.869-3.991,9.719-10.376,9.719-6.057,0-9.39-3.474-10.329-8.686l5.728-1.173c.563,2.77,2.019,4.414,4.553,4.414,2.442,0,4.226-1.5,4.226-4.7v-18.076H402.083v-5.071Z" transform="translate(-397.904 -3935.848)" fill="#172d54"/>
          <path d="M442.03,3936.411v20.142c0,5.118,1.784,7.7,6.479,7.7s6.479-2.583,6.479-7.7v-20.142h6.1v19.344c0,9.625-3.428,13.991-12.63,13.991s-12.536-4.366-12.536-13.991v-19.344Z" transform="translate(-407.945 -3935.848)" fill="#172d54"/>
          <path d="M475.88,3964.1l4.179-4.038a10.675,10.675,0,0,0,8.4,4.038c3.8,0,5.775-1.832,5.775-4.6,0-2.207-1.033-3.568-4.46-4.178l-3.1-.469c-6.62-1.033-9.813-4.179-9.813-9.531,0-5.869,4.554-9.671,11.878-9.671,4.93,0,8.592,1.69,11.222,4.836l-4.226,3.99a8.725,8.725,0,0,0-7.324-3.38c-3.522,0-5.352,1.409-5.352,3.85,0,2.489,1.408,3.521,4.553,4.084l3.052.564c6.62,1.174,9.719,4.131,9.719,9.531,0,6.2-4.461,10.423-12.3,10.423A15.208,15.208,0,0,1,475.88,3964.1Z" transform="translate(-418.496 -3935.647)" fill="#172d54"/>
          <path d="M525.786,3941.9v27.277h-6.2V3941.9h-9.3v-5.493h24.79v5.493Z" transform="translate(-427.583 -3935.848)" fill="#172d54"/>
          <path d="M548.7,3969.182v-4.976h4.32v-22.817H548.7v-4.978h14.883v4.978h-4.366v22.817h4.366v4.976Z" transform="translate(-437.725 -3935.848)" fill="#172d54"/>
          <path d="M573.978,3952.83c0-11.033,5.493-17.183,14.132-17.183,5.868,0,9.671,2.488,11.973,7.559l-5.306,2.817a6.484,6.484,0,0,0-6.667-4.882c-4.6,0-7.559,3.333-7.559,8.92v5.165c0,5.634,2.958,8.827,7.559,8.827a7.284,7.284,0,0,0,7.09-5.211l5.023,2.958c-2.347,4.882-6.244,7.747-12.112,7.747C579.471,3969.544,573.978,3963.864,573.978,3952.83Z" transform="translate(-444.401 -3935.647)" fill="#172d54"/>
          <path d="M616.029,3969.182v-32.771h21.6v5.493h-15.4v7.7h13.615v5.494H622.226v8.591h15.4v5.493Z" transform="translate(-455.506 -3935.848)" fill="#172d54"/>
          <path d="M676.19,3953.783H658.208v15.4h-2.817v-32.771h2.817v14.883H676.19v-14.883h2.816v32.771H676.19Z" transform="translate(-465.9 -3935.848)" fill="#172d54"/>
          <path d="M700.905,3936.411v20.236c0,7.277,2.065,10.563,8.5,10.563,6.386,0,8.5-3.287,8.5-10.563v-20.236h2.817v19.625c0,9.625-3,13.709-11.408,13.709s-11.221-4.084-11.221-13.709v-19.625Z" transform="translate(-477.176 -3935.848)" fill="#172d54"/>
          <path d="M739.445,3936.411h12.818c5.118,0,8.216,3.1,8.216,8.263,0,4.554-2.582,6.573-5.023,7.137V3952c3.052.376,6.291,2.817,6.291,7.935,0,5.4-3.286,9.249-8.311,9.249H739.445Zm2.817,14.507h9.719c3.568,0,5.493-2.159,5.493-5.118v-1.784c0-3-1.924-5.118-5.493-5.118h-9.719Zm0,15.776h10.423c3.944,0,6.057-2.395,6.057-5.822v-1.737c0-3.38-2.113-5.775-6.057-5.775H742.262Z" transform="translate(-488.097 -3935.848)" fill="#172d54"/>
        </g>
        <g transform="translate(249.202 3900.297)">
          <path d="M315.475,3975.863q-4.9-3.288-9.791-6.584a5.561,5.561,0,0,0,.286-4.536,5.066,5.066,0,0,0-1.986-2.441,9.75,9.75,0,0,0,1.721-3.625,21.368,21.368,0,0,0,.6-6.9,11.731,11.731,0,0,0-1-4.34,9.624,9.624,0,0,0-1.586-2.615,23.308,23.308,0,0,0,1.749-4.649,30.338,30.338,0,0,0,.815-9.115,22.441,22.441,0,0,0-1.824-8.19c11.655.546,21.911,4.935,28.514,11.487a35.337,35.337,0,0,0-61.962,0c6.558-6.507,16.719-10.875,28.274-11.47a19.87,19.87,0,0,0-1.657,6.2,17.484,17.484,0,0,0-.277,4.277c.119,1.113.132,2.234.277,3.344a19.691,19.691,0,0,0,1.624,6.16,14.638,14.638,0,0,0,.907,1.849c-1.646,1.645-2.209,4.059-2.615,6.313a23.764,23.764,0,0,0,.018,5.21,12.389,12.389,0,0,0,2.183,6.072,5.073,5.073,0,0,0-2.284,3.639,5.707,5.707,0,0,0,.576,3.317q-4.792,3.316-9.588,6.626a6.3,6.3,0,0,0-2.71,3.287,3.83,3.83,0,0,0,.984,3.481,11.075,11.075,0,0,0,3.932,2.64,26.393,26.393,0,0,0,7.3,1.82,35.763,35.763,0,0,0,9.849-.25,23.006,23.006,0,0,0,6.568-2.059,8.633,8.633,0,0,0,3.4-2.838,3.518,3.518,0,0,0,.411-3.041A6.6,6.6,0,0,0,315.475,3975.863Zm-13.487-53.056h0Z" transform="translate(-254.96 -3904.447)" fill="#062b55"/>
          <path d="M263.512,3992.28l.529.812-4.24,2.764-.529-.812-.925.6,1.8,2.766.924-.6-.523-.8,4.24-2.764.523.8.925-.6-1.8-2.766Z" transform="translate(-251.617 -3923.973)" fill="#062b55"/>
          <path d="M262.127,3987.611l-4.738,2.233-.013-.028,1.046-1.322,2.44-3.567-.644-1.366-6.575,3.1.554,1.178,4.739-2.233.013.028-1.047,1.322-2.44,3.567.644,1.366,6.575-3.1Z" transform="translate(-250.375 -3921.909)" fill="#062b55"/>
          <path d="M252.871,3981.24l5.81-1.688-.384-1.32-5.809,1.688-.575-1.98-1.171.341,1.535,5.279,1.17-.34Z" transform="translate(-249.609 -3920.462)" fill="#062b55"/>
          <path d="M256.606,3968.387l-1.206.171.478,3.381-1.886.267-.423-2.99-1.206.171.422,2.99-1.69.238-.478-3.38-1.206.17.671,4.742,7.195-1.018Z" transform="translate(-249.257 -3917.98)" fill="#062b55"/>
          <path d="M256.491,3963.774l-2.8-.044.021-1.3,2.822-1.308.026-1.531-2.981,1.441a2,2,0,0,0-2.039-1.387,2.046,2.046,0,0,0-2.284,2.108l-.053,3.279,7.266.117Zm-3.956-.064-2.114-.034.029-1.77a.757.757,0,0,1,.826-.84l.479.007a.765.765,0,0,1,.809.867Z" transform="translate(-249.202 -3915.696)" fill="#062b55"/>
          <path d="M256.942,3954.711l-5.157-.919.006-.03,1.618-.474,4.054-1.5.265-1.487-7.155-1.275-.229,1.281,5.157.919-.005.031-1.618.473-4.053,1.5-.265,1.486,7.155,1.275Z" transform="translate(-249.296 -3912.949)" fill="#062b55"/>
          <path d="M259.108,3946.479l-1.549-1.167.874-2.429,1.941.079.484-1.342-7.665-.193-.578,1.606,6.021,4.758Zm-2.543-1.878-2.5-1.885.018-.048,3.128.131Z" transform="translate(-250.103 -3910.978)" fill="#062b55"/>
          <path d="M256.762,3934.981l5.364,2.8.635-1.219-5.364-2.8.953-1.828-1.081-.563-2.541,4.875,1.08.563Z" transform="translate(-250.662 -3908.366)" fill="#062b55"/>
          <path d="M260.521,3928.924l4.178,2.852-.539.791.911.622,1.86-2.725-.911-.622-.545.8-4.179-2.852.546-.8-.911-.622-1.86,2.725.911.622Z" transform="translate(-251.808 -3907.066)" fill="#062b55"/>
          <path d="M270.837,3925.953c1.284-1.423,1.155-3.222-.669-4.868s-3.627-1.591-4.911-.169-1.155,3.222.669,4.868S269.56,3927.366,270.837,3925.953Zm-4.676-4.221a1.718,1.718,0,0,1,2.613.059l.835.752a1.726,1.726,0,1,1-2.288,2.535l-.835-.753A1.72,1.72,0,0,1,266.161,3921.732Z" transform="translate(-253.218 -3905.373)" fill="#062b55"/>
          <path d="M276.168,3919.951l-3.229-4.125.024-.02,1.522.727,4.02,1.588,1.189-.931-4.481-5.724-1.025.8,3.229,4.125-.025.019-1.521-.727-4.02-1.587-1.189.931,4.482,5.724Z" transform="translate(-254.869 -3903.247)" fill="#062b55"/>
          <path d="M284.247,3915.156l-.379-1.9,2.27-1.229,1.4,1.352,1.254-.68-5.589-5.248-1.5.813,1.323,7.559Zm-1.208-6.188,2.247,2.181-1.685.912-.608-3.068Z" transform="translate(-257.783 -3902.186)" fill="#062b55"/>
          <path d="M295.153,3910.317l-.421-1.144-2.687.988-2.087-5.676-1.29.474,2.509,6.82Z" transform="translate(-259.624 -3901.403)" fill="#062b55"/>
          <path d="M304.349,3908.085a1.952,1.952,0,0,0,1.527-2.348,1.593,1.593,0,0,0-1.816-1.432l-.011-.062a1.364,1.364,0,0,0,1.043-1.746,1.767,1.767,0,0,0-2.193-1.541l-3.322.524,1.132,7.179Zm.072-2.351.055.35a.74.74,0,0,1-.7.912l-1.893.3-.3-1.924,1.892-.3A.748.748,0,0,1,304.422,3905.734Zm-1.629-3.58a.711.711,0,0,1,.9.638l.051.319a.717.717,0,0,1-.662.884l-1.676.265-.29-1.841Z" transform="translate(-262.505 -3900.463)" fill="#062b55"/>
          <path d="M311.047,3904.785l1.3.02,1.309,2.823,1.531.025-1.442-2.98a2,2,0,0,0,1.386-2.04,2.047,2.047,0,0,0-2.108-2.284l-3.28-.052-.117,7.266,1.374.022Zm.053-3.269,1.77.028a.758.758,0,0,1,.84.826l-.008.479a.764.764,0,0,1-.866.809l-1.771-.029Z" transform="translate(-265.159 -3900.297)" fill="#062b55"/>
          <path d="M321.39,3907.156l-.96-.126.663-5.017.96.127.145-1.094-3.273-.433-.145,1.094.95.125-.664,5.017-.949-.126-.145,1.094,3.272.433Z" transform="translate(-267.363 -3900.38)" fill="#062b55"/>
          <path d="M326.183,3909.311c1.829.5,3.343-.354,3.977-2.685s-.244-3.831-2.072-4.328l-2.552-.692-1.905,7.012Zm.36-6.17,1.226.333a1.6,1.6,0,0,1,1.134,2.217l-.3,1.1a1.6,1.6,0,0,1-2.1,1.339l-1.226-.333Z" transform="translate(-268.857 -3900.643)" fill="#062b55"/>
          <path d="M334.919,3912.767a1.728,1.728,0,0,0,2.254-.329l.038.018-.474,1.019,1.076.5,1.669-3.587-2.492-1.159-.488,1.048,1.293.6-.2.424c-.347.746-1.155.83-1.929.469a1.727,1.727,0,0,1-.807-2.535l.492-1.057a1.716,1.716,0,0,1,2.44-1.023,1.662,1.662,0,0,1,1.02,1.68l1.287-.124a2.868,2.868,0,0,0-1.793-2.66c-1.755-.817-3.5-.149-4.53,2.06S333.371,3912.047,334.919,3912.767Z" transform="translate(-271.403 -3901.728)" fill="#062b55"/>
          <path d="M344.615,3918.23l.682-1.009-2.829-1.911,1.066-1.579,2.5,1.69.682-1.01-2.5-1.689.955-1.415,2.83,1.912.681-1.01-3.968-2.68-4.067,6.021Z" transform="translate(-273.351 -3902.735)" fill="#062b55"/>
          <path d="M350.72,3919.458l.438.54c.47.612.436.989.106,1.352-.413.453-1.01.459-1.633-.109a2.364,2.364,0,0,1-.774-1.917l-1.289.037a3.372,3.372,0,0,0,1.187,2.716c1.286,1.171,2.648,1.146,3.574.129.807-.884.74-1.832-.169-3.014l-.417-.548c-.431-.562-.508-.942-.136-1.351s.876-.356,1.453.169a1.936,1.936,0,0,1,.7,1.648l1.289-.023a3.056,3.056,0,0,0-1.116-2.469c-1.2-1.094-2.515-1.151-3.392-.189C349.736,3917.307,349.789,3918.3,350.72,3919.458Z" transform="translate(-275.178 -3904.378)" fill="#062b55"/>
          <path d="M355.873,3929.212l.817,1.1,4.862-3.6,1.226,1.657.979-.725-3.269-4.418-.979.724,1.226,1.657Z" transform="translate(-277.371 -3906.252)" fill="#062b55"/>
          <path d="M362.273,3932.6c-2.171,1.151-2.73,2.874-1.838,4.558s2.634,2.188,4.8,1.036,2.735-2.865,1.838-4.558S364.445,3931.444,362.273,3932.6ZM366,3934.2a1.72,1.72,0,0,1-.948,2.436l-.994.527a1.726,1.726,0,1,1-1.6-3.018l.994-.527A1.719,1.719,0,0,1,366,3934.2Z" transform="translate(-278.469 -3908.521)" fill="#062b55"/>
          <path d="M366.973,3947.85a.907.907,0,0,1-1.26-.611,1.02,1.02,0,0,1,.659-1.242l-.6-1.148a2.152,2.152,0,0,0-1.213,2.737,2.086,2.086,0,0,0,2.71,1.61l5.019-1.454-1.02-3.52-1.08.313.637,2.2Z" transform="translate(-279.633 -3911.707)" fill="#062b55"/>
          <path d="M366.312,3955.606c.27,2.023,1.331,2.648,3.446,2.366l4.253-.568-.18-1.342-4.427.591c-1.125.15-1.746-.166-1.883-1.2s.378-1.5,1.5-1.65l4.427-.592-.179-1.341-4.253.568C366.9,3952.722,366.042,3953.582,366.312,3955.606Z" transform="translate(-280.115 -3913.69)" fill="#062b55"/>
          <path d="M372.32,3966.133l.864.957a3.059,3.059,0,0,0,1.127-2.465c.036-1.625-.785-2.653-2.086-2.682-1.188-.026-1.9.667-2.162,2.13l-.118.685c-.152.756-.46.979-.949.968-.614-.013-1.011-.46-.993-1.3a2.368,2.368,0,0,1,.937-1.843l-.875-.947a3.373,3.373,0,0,0-1.267,2.68c-.038,1.739.877,2.748,2.251,2.778,1.2.026,1.868-.646,2.161-2.108l.141-.675c.139-.694.375-1,.927-.989s.845.425.828,1.206A1.937,1.937,0,0,1,372.32,3966.133Z" transform="translate(-280.256 -3916.227)" fill="#062b55"/>
          <path d="M372.334,3972.435l-5.973-.96-.218,1.356,5.973.961-.328,2.035,1.2.194.873-5.428-1.2-.193Z" transform="translate(-280.083 -3918.504)" fill="#062b55"/>
          <path d="M370.865,3979.452l-4.866-1.39.263-.921-1.061-.3-.907,3.174,1.062.3.266-.931,4.866,1.39-.265.931,1.061.3.907-3.175-1.062-.3Z" transform="translate(-279.595 -3920.178)" fill="#062b55"/>
          <path d="M366.82,3983.718c-2.248-.968-3.887-.347-4.644,1.413a2.791,2.791,0,0,0,.516,3.147l1.043-.764a1.615,1.615,0,0,1-.44-1.9,1.685,1.685,0,0,1,2.46-.766l1.053.452a1.709,1.709,0,0,1,1.154,2.323,1.439,1.439,0,0,1-1.579.93l.109,1.328a2.651,2.651,0,0,0,2.59-1.776C369.84,3986.343,369.068,3984.685,366.82,3983.718Z" transform="translate(-278.964 -3921.84)" fill="#062b55"/>
          <path d="M357.078,3994.028l1.034.643,1.8-2.9,1.618,1-1.594,2.564,1.035.643,1.593-2.563,1.45.9-1.8,2.9,1.034.643,2.528-4.066-6.171-3.836Z" transform="translate(-277.69 -3923.519)" fill="#062b55"/>
          <path d="M272.812,3955.1c1.093-1.861,2.14-3.742,3.164-5.636s2.011-3.8,2.98-5.723l1.433-2.889.021-.042.02.042c.479.962.946,1.929,1.434,2.888q1.454,2.879,2.981,5.723c1.024,1.893,2.071,3.775,3.163,5.636s2.206,3.711,3.429,5.509c-.615-2.086-1.354-4.114-2.085-6.145-.746-2.026-1.537-4.028-2.351-6.021s-1.664-3.967-2.533-5.934c-.431-.984-.883-1.96-1.323-2.94l-1.36-2.923a1.517,1.517,0,0,0-2.752,0l-1.362,2.922-1.325,2.94c-.868,1.966-1.721,3.941-2.534,5.934s-1.605,4-2.351,6.021c-.729,2.032-1.468,4.06-2.081,6.147C270.605,3958.806,271.7,3956.948,272.812,3955.1Z" transform="translate(-254.531 -3909.457)" fill="#062b55"/>
          <path d="M344.481,3948.438q-1.223-2.987-2.533-5.934c-.432-.984-.884-1.96-1.324-2.94l-1.36-2.923a1.517,1.517,0,0,0-2.752,0l-1.362,2.922-1.324,2.94c-.868,1.966-1.721,3.941-2.534,5.934s-1.605,4-2.351,6.021c-.73,2.032-1.469,4.06-2.082,6.147,1.225-1.8,2.324-3.655,3.433-5.508,1.092-1.861,2.139-3.742,3.163-5.636s2.01-3.8,2.98-5.723l1.433-2.889.021-.042.02.042c.478.962.946,1.929,1.433,2.888q1.454,2.879,2.981,5.723c1.024,1.893,2.071,3.775,3.163,5.636,1.108,1.854,2.206,3.711,3.429,5.509-.615-2.086-1.354-4.114-2.085-6.145C346.085,3952.433,345.294,3950.431,344.481,3948.438Z" transform="translate(-269.71 -3909.457)" fill="#062b55"/>
          <path d="M292.738,3975.53a1.23,1.23,0,0,0-1.16-1.623H267.709a1.229,1.229,0,0,0-1.159,1.623,13.782,13.782,0,0,0,26.188,0Z" transform="translate(-253.766 -3919.39)" fill="#062b55"/>
          <path d="M349.058,3973.907H325.19a1.229,1.229,0,0,0-1.16,1.623,13.782,13.782,0,0,0,26.189,0A1.23,1.23,0,0,0,349.058,3973.907Z" transform="translate(-268.946 -3919.39)" fill="#062b55"/>
        </g>
      </g>
    </g>
    
  </svg>
);
